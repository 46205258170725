/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
// Default
import Page from './pages/page'

// Pages
import PageHome from './pages/page-home'
import PageOurStory from './pages/page-our-story'
import PageFaq from './pages/page-faq'
import PageLabeledOffer from './pages/page-labeled-offer'
import PageOfferDetails from './pages/page-offer-details'
import PageWarrantyDetails from './pages/page-warranty-details'
import PageContact from './pages/page-contact'
import PageReferral from './pages/page-referral'
import PageCallback from './pages/page-callback'
import PageNewsletter from "./pages/page-newsletter"
import PageGutenberg from './pages/page-gutenberg'
import PageSimulationChoices from './pages/page-simulation-choices'
import PageLandingDevis from './pages/page-landing-devis'

// Single pages
import SinglePost from './pages/single-post'
import SingleProfile from './pages/single-profile'

// Archive pages
import ArchivePosts from './pages/archive-posts'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    // Default
    default: Page,
    
    // Pages
    pageHome: PageHome,
    pageOurStory: PageOurStory,
    pageFaq: PageFaq,
    pageLabeledOffer: PageLabeledOffer,
    pageOfferDetails: PageOfferDetails,
    pageWarrantyDetails: PageWarrantyDetails,
    pageContact: PageContact,
    pageReferral: PageReferral,
    pageCallback: PageCallback,
    pageNewsletter: PageNewsletter,
    pageGutenberg: PageGutenberg,
    pageSimulationChoices: PageSimulationChoices,
    pageLandingDevis: PageLandingDevis,

    // Single pages
    singlePost: SinglePost,
    singleProfile: SingleProfile,

    // Archive pages
    archivePosts: ArchivePosts,
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
