import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Accordions from '../modules/accordions'
import WknSwiper from '../modules/wkn-swiper'

export default class PageLandingDevis extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      testimonialsAlternativeSwiper: viewStorage.current.querySelector('.TestimonialsAlternative .swiper'),
      relatedQuestions: viewStorage.current.querySelector('.RelatedQuestions')
    }

    this.init()
  }

  init() {
    const {
      testimonialsAlternativeSwiper = null,
      relatedQuestions = null
    } = this.DOM

    if (testimonialsAlternativeSwiper) this.testimonialsSwiperModule = new WknSwiper(testimonialsAlternativeSwiper)
    if (relatedQuestions) this.accordionsModule = new Accordions(relatedQuestions)
  }

  onLeaveCompleted() {
    this.testimonialsSwiperModule && this.testimonialsSwiperModule.destroy()
    this.accordionsModule && this.accordionsModule.destroy()
  }
}
